<template>
  <section :class="['shared-kit-progress-counter', counterClass]">
    <div class="shared-kit-progress-counter__filled"></div>
    <div class="shared-kit-progress-counter__text">{{ text }}</div>
  </section>
</template>

<script setup lang="ts">
import { ESharedKitProgressCounterTypes, type ISharedKitProgressCounterProps } from './SharedKitProgressCounter.types';

const props = defineProps<ISharedKitProgressCounterProps>();
const { variant } = toRefs(props);

const refinedTime = ref(0);
const { timerData, start, stop } = useTimer(refinedTime);

onBeforeMount(() => {
  watch(
    variant,
    (value) => {
      if (value.type === ESharedKitProgressCounterTypes.QUANTITY) {
        stop();
        return;
      }

      refinedTime.value = value.end;
      start();
    },
    { deep: true, immediate: true },
  );
});

const text = computed(() => {
  if (props.variant.type === ESharedKitProgressCounterTypes.QUANTITY) {
    return `${props.variant.current} / ${props.variant.initial}`;
  }

  const { days, hours, minutes, seconds } = timerData.value;
  const parsedDays = +days;
  const dayText = parsedDays > 0 ? `${parsedDays}д  ` : '';

  return `${dayText}${hours}:${minutes}:${seconds}`;
});

const counterClass = computed(() => ({
  'shared-kit-progress-counter--low': filledPercent.value <= 10,
}));

const timeDistance = computed(() => {
  if (props.variant.type === ESharedKitProgressCounterTypes.QUANTITY) return 0;
  return props.variant.end - props.variant.start;
});

const filledPercent = computed(() => {
  if (props.variant.type === ESharedKitProgressCounterTypes.QUANTITY) {
    return (props.variant.current / props.variant.initial) * 100;
  }

  const { rawTime } = timerData.value;
  return (rawTime / timeDistance.value) * 100;
});

const filledWidth = computed(() => GlobalUtils.CSS.proceedCssValue(filledPercent.value, '%'));
</script>

<style scoped lang="scss">
.shared-kit-progress-counter {
  --filled-width: v-bind('filledWidth');
}
</style>

<style scoped lang="scss" src="./SharedKitProgressCounter.scss" />
